import API from 'Api'
import Fuse from 'fuse.js'
import React, { useCallback, useEffect, useState } from 'react'
import { Link, Switch } from 'react-router-dom'
import { StaffRoute } from 'Routes/Helpers'
import { AdminMembership } from 'Routes/Household/AdminMembers'
import { Button, Form,  Grid,  Loader, Message, Segment, Table } from 'semantic-ui-react'
import { useFields, useRequest } from 'Shared/Hooks'
import { MembershipStatusLabel, MembershipStatusSelect } from 'Shared/MembershipStatus'
import { Invite, Membership, MembershipStatus } from 'Shared/Models'
import TableHeaders from 'Shared/TableHeaders'

const Memberships = () => {
  return <Switch>
    <StaffRoute exact path="/admin/memberships" component={AdminMembershipLists}/>
    <StaffRoute path="/admin/memberships/invite" component={InviteForm}/>
  </Switch>
}


export default Memberships;

const AdminMembershipLists = () => (
  <Grid columns={2}>
    <Grid.Column>
      <Segment>
        <MembershipList/>
      </Segment>
    </Grid.Column>
    <Grid.Column>
      <div style={{marginBottom: '1em'}}>
        <Button primary as={Link} to='/admin/memberships/invite'>Send Invites</Button>
      </div>
      <InviteList/>
    </Grid.Column>
  </Grid>
);

const MembershipList = () => {
  const [loading, error, run, memberships] = useRequest([], {loading: true})
  const {fields, handleChange} = useFields({
    filter: '',
    status: 'any',
    is_org: false,
    not_org: false,
    has_extras: false,
  });

  const [fuse, setFuse] = useState<Fuse<Membership> | null>(null);

  useEffect(()=>{
    run(API.getMemberships())
  }, [run])

  useEffect(()=>{
    setFuse(new Fuse(memberships, {
      keys: ['household.name']
    }));
  }, [memberships])

  const { filter, status, is_org, not_org, has_extras} = fields;

  let filtered = filter && fuse ? fuse.search(filter).map(({item}) => (item)) : 
    fuse ? memberships : [];

  // filter by status
  if (status === 'onboarding') {
    filtered = filtered.filter(m=>(m.status !== MembershipStatus.Active && m.status !== MembershipStatus.Canceled));
  } else if (status !== 'any') {
    filtered = filtered.filter(m=>(m.status === Number(status)));
  }

  // filter by org
  if (is_org) {
    filtered = filtered.filter(m=>m.household.is_org)
  }
  if (not_org) {
    filtered = filtered.filter(m=>!m.household.is_org)
  }
  if (has_extras) {
    filtered = filtered.filter(m=>m.extras > 0)
  }

  return <>
    <Form>
      <Form.Field>
        <Form.Input
          type='text'
          name='filter'
          placeholder={`Filter ${filtered.length} memberships`}
          value={filter}
          onChange={handleChange} />
      </Form.Field>
      <Form.Field>
        <MembershipStatusSelect basic value={status} onChange={handleChange} forFilter/>
      </Form.Field>
      <Form.Group inline>
        <Form.Checkbox
          label='Is&nbsp;Org'q
          name='is_org'
          checked={is_org}
          onChange={handleChange}/>
        <Form.Checkbox
          label='Not&nbsp;Org'
          name='not_org'
          checked={not_org}
          onChange={handleChange}/>
        <Form.Checkbox
          label='Has&nbsp;Extras'
          name='has_extras'
          checked={has_extras}
          onChange={handleChange}/>
      </Form.Group>
    </Form>

    {loading && <Loader active inline='centered' />}
    {error && <Message negative>{error}</Message>}
    {filtered.length === 0 && !loading ? 'No memberships match your filter...' : 
      <Table compact unstackable>
        <TableHeaders headers={['Group', 'Updated', 'Status']}/>
        <Table.Body>
          {filtered.map(m => (
            <Table.Row key={m.id}>
              <Table.Cell>
                <Link to={`/household/${m.household.id}/admin`}>{m.household.name}</Link>
              </Table.Cell>
              <Table.Cell content={m.updated_at.substring(0,10)}/>
              <Table.Cell collapsing>
                <MembershipStatusLabel size='tiny' status={m.status}/>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>}
  </>
}

const InviteList = () => {
  const [loading, error, run, invites] = useRequest<Invite[]>([], {loading: true})
  
  const load = useCallback(()=>{
    run(API.getPendingInvites())
  }, [run])

  useEffect(load, [load]);

  return <div className="ui form">
    {loading && <Loader active inline='centered' />}
    {error && <Message negative>{error}</Message>}
    {invites.length > 0 && <>
      <Table compact>
        <TableHeaders headers={['Invited to Onboard', 'Date', 'Hide']} />
        <Table.Body>
          {invites.map(i=>(
            <Table.Row key={i.email}>
              <Table.Cell content={i.email}/>
              <Table.Cell collapsing content={i.updated_at.substring(0, 10)} />
              <Table.Cell collapsing>
                <DeleteInvite email={i.email} onSuccess={load}/>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>}
  </div>
}

const DeleteInvite = ({email, onSuccess}: {email: string, onSuccess: () => void}) => {
  const [loading, error, run] = useRequest(false)
  const hide = () => {
    run(API.hideMembershipInvite(email), onSuccess)
  }

  if (loading) {
    return <Loader active inline/>
  }

  if (error) {
    return <>{error}</>
  }

  return <Button title='Hide this invite' size='mini' onClick={hide} icon='close'/>;
}

const emptyInvite = {
  user: {
    email: '',
    person: {
      first: '',
      last: ''
    }
  },
  household: {
    name: '',
    is_org: false,
  }
}

const InviteForm = () => {
  const [loading, error, run, result] = useRequest({success: false})
  const {fields, handleChange, setFields} = useFields(emptyInvite)

  const handleSubmit = ()=> {
    run(API.createMembershipInvite(fields), ()=>{
      setFields(emptyInvite)
    })
  }

  return <>
    <div style={{marginBottom: '1em'}}>
      <Button as={Link} to='/admin/memberships'>Back to Memberships</Button>
    </div>
    <Segment>
      <Form error name="invite" loading={loading} onSubmit={handleSubmit}>
        <Message error>{error}</Message>
        {result && result.success && <Message>Invite Sent!</Message>}
        <Form.Input
          name="user.email"
          value={fields.user.email}
          type="email"
          style={{width:'15em'}}
          required
          label='Email Address'
          placeholder="new@member.com"
          onChange={handleChange} />

        <Form.Input
          name="user.person.first"
          value={fields.user.person.first}
          type="text"
          style={{width:'15em'}}
          required
          label='First Name'
          onChange={handleChange} />

        <Form.Input
          name="user.person.last"
          value={fields.user.person.last}
          type="text"
          style={{width:'15em'}}
          required
          label='Last Name'
          onChange={handleChange} />

        <Form.Input
          style={{maxWidth: '14em'}}
          name='household.name'
          required
          label='Group Name'
          value={fields.household.name}
          onChange={handleChange}
        />

        <Form.Checkbox
          name='household.is_org'
          label='Group is an Organization'
          checked={fields.household.is_org}
          onChange={handleChange}
        />

        <Button primary type="submit">Invite to Onboard</Button>
      </Form>
    </Segment>
  </>
}
