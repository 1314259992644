import './table.css'

import API from 'Api';
import { UserContainer } from 'Context/User'
import qs from 'qs'
import React, { useCallback, useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useLocation, useRouteMatch } from 'react-router'
import { Redirect } from 'react-router-dom'
import { Button, Grid, Header, Icon, Image, Label, List, Loader, Message, Modal, Segment } from 'semantic-ui-react'
import { ProgramDate } from 'Shared/Date';
import Dollars from 'Shared/Dollars'
import { useRequest } from 'Shared/Hooks'
import { Markdown } from 'Shared/Markdown';
import { Program, ProgramStatus, RegistrationType, User } from 'Shared/Models';
import { Panel } from 'Shared/Panel'
import PersonLabel from 'Shared/PersonLabel'
import { ProgramStatusLabel } from 'Shared/ProgramStatus'
import { Member, Staff } from 'Shared/Roles'

import { Images } from './Images'
import { NoiseLevels } from './NoiseSelect'
import { links, locations } from './options'
import { membershipURL, RegistrationTrigger } from './Registration';
import { Reservables } from './ReservableSelect'


export const stripMarkdownLinks = (str: string) => {
  if (!str) {
    return '';
  }
  return str.replace(/\[(.*?)\]\(((?:mailto:|https:\/\/|http:\/\/)[\w\d./\-@?=#()]+)\)/g, function() {
    return arguments[1];
  });
}

const gmaps = 'https://www.google.com/maps/place/Alder+Commons/@45.5549121,-122.6243284,14.47z/data=!4m8!1m2!2m1!1salder+commons!3m4!1s0x5495a7533068f1cf:0x64a74de86f1a92ed!8m2!3d45.5551697!4d-122.6201248';

type ProgramViewProps = {
  program: Program
  load: ()=>void
};


const cancelable = [
  ProgramStatus.RequestReserved,
  ProgramStatus.Reserved,
  ProgramStatus.RequestPublic,
];

const ProgramView: React.FC<ProgramViewProps>= ({program, load}) => {
  const {user} = useContext(UserContainer)
  const match = useRouteMatch<{slug_or_id: string}>();
  const location = useLocation();
  const {fresh} = qs.parse(location.search, { ignoreQueryPrefix: true });

  // redirect to slug!
  if (program.slug && !isNaN(parseInt(match.params.slug_or_id)) && match.params.slug_or_id !== program.slug) {
    let tail = location.pathname.split('/')[3];
    if (!tail) {
      tail = '';
    }
    return <Redirect to={`/program/${program.slug}/${tail}`}/>
  }

  return <>
    <Helmet>
        {program.status === ProgramStatus.Draft && <style>{`
        body { background-color: #fad29d }; 
      `}</style>}
    </Helmet>
    {program.status === ProgramStatus.Draft && <Message warning>This program isn't published yet!!!</Message>}
    <Grid stackable>
      <Grid.Column computer={10} mobile={16} style={{paddingBottom: 0}}>
        {fresh && (program.status === ProgramStatus.RequestPublic || program.status === ProgramStatus.RequestReserved) ? <Message warning>
          <Message.Header>We cannot guarantee space reservations!</Message.Header>
          If you do not hear back with us with a confirmation, you're welcome to still come in and see if the space is available, but that does not mean that you have the space reserved.
        </Message> : <Images program={program}/>}

        <Headers hostPrefix='With' program={program}/>
        <Markdown className="prog-description" text={program.description}/>
      </Grid.Column>
      <Grid.Column computer={6} mobile={16}>
        <CourseInfo program={program}/>

        <RegistrationTrigger program={program}/>
        {program.organizers?.includes(user.id) && cancelable.includes(program.status) &&
          <div style={{marginTop:'1em'}}>
            <Cancel label='Cancel Reservation' program={program} onSuccess={load}/>
          </div>
        }
        <StaffProgramDeets program={program} load={load}/>

      </Grid.Column>
    </Grid>
  </>
}

export default ProgramView;

export const CancellationModal: React.FC<{triggerText: string}> = ({triggerText}) => (
  // eslint-disable-next-line 
  <Modal closeIcon='close' trigger={<a>{triggerText}</a>}>
    <Modal.Header>Cancellation Policy</Modal.Header>
    <Modal.Content>
      <p>Program participants can cancel a registration for a program at Alder Commons at any time.</p>
      <p>Refunds may be issued if the cancellation is <b>at least one week before the start date</b>, or if <i>the program itself</i> is canceled by Alder Commons.</p>
    </Modal.Content>
  </Modal>
)

const CourseInfo = ({program} : {program: Program}) => {
  const seats = program.capacity - program.participants - program.waitlisters;
  const fifteenMinutesAgo = new Date().getTime() - (15 * 60 * 1000);
  const isAfterProgramStart = new Date(program.start_date).getTime() < fifteenMinutesAgo;
  const isBeforeProgramEnd = new Date(program.end_date).getTime() > new Date().getTime();
  
  return (
    <Segment style={{background: '#f2f9ea', border: 0}}>
      <ProgramDate program={program}/>
      {isAfterProgramStart && isBeforeProgramEnd && <Message warning>This course already started.</Message>}
      <List>
        <ViewLocation program={program}/>
        <Member>
          <ViewReservations program={program}/>
        </Member>

        {program.location === locations.ac &&
          <List.Item>
            <Icon name='stethoscope' title='Covid Policy'/>
            view our <a target='_blank' rel='noopener noreferrer' href='https://aldercommons.org/covid/'>COVID Policy</a>
          </List.Item>}
        {(program.registration_type === RegistrationType.PaymentRequired || program.registration_type === RegistrationType.PaymentOptional) &&
          <List.Item>
            <Icon name='ban' title='Cancellation Policy'/>
            view our <CancellationModal triggerText='Cancellation Policy' />
          </List.Item>}

        {(program.registration_type === RegistrationType.PaymentRequired || program.registration_type === RegistrationType.PaymentOptional) &&
          <List.Item>
            <Icon name='tag'/>
            <Dollars amount={program.registration_type === RegistrationType.PaymentOptional ? 0 : program.min_price}/>&ndash;<Dollars amount={program.min_price * 2}/>
          </List.Item>}
        { program.capacity > 0 &&
          <List.Item>
            <Icon name='user'/>
            {seats >= 0 ? seats : 0} spot{seats === 1 ? '' : 's'} left
          </List.Item>}
        { program.registration_type === RegistrationType.MembersOnly &&
          <List.Item>
            <Icon name='star'/>
            <a href={membershipURL}>Members Only</a>
          </List.Item>}
      </List>
      <PugsOrMembersOnly program={program}/>
    </Segment>
  );
}

type StaffProgramDeetsProps = {
  program: Program,
  load: () => void
}

const StaffProgramDeets: React.FC<StaffProgramDeetsProps> = ({program, load}) => (
  <Staff>
    <Panel icon='lock' heading='Staff'>
      {program.link && <>
        <Header as='h4'>Program Link</Header>
        <a href={program.link}>{program.link === links.zoom ? 'hello' : program.link}</a>
      </>}

      <Header as='h4'>Organizer</Header>
      <div style={{marginBottom: '1em'}}>
        {program.organizers?.map(id =>
          <Organizer key={id} id={id}/>
        )}
      </div>

      {program.equipment_needs && <>
        <Header as='h4'>Equipment Needs</Header>
        <Markdown text={program.equipment_needs}/>
      </>}

      {program.noise_level > 0 && <>
        <Header as='h4'>Noise Level</Header>
        <p>{NoiseLevels[program.noise_level]}</p>
      </>}

      {program.setup_duration > 0 && <>
        <Header as='h4'>Setup Duration</Header>
        <p>{program.setup_duration} minutes</p>
      </>}

      {program.cleanup_duration > 0 && <>
        <Header as='h4'>Cleanup Duration</Header>
        <p>{program.cleanup_duration} minutes</p>
      </>}

      {program.do_not_disturb > 0 && <Header as='h4'>DO NOT DISTURB</Header>}

      {(program.status === ProgramStatus.RequestReserved || program.status === ProgramStatus.RequestPublic) && <>
        <Header as='h4'>Approve?</Header>
        <ApproveOrCancel program={program} onSuccess={load}/>
      </>}
    </Panel>
  </Staff>
)

const Organizer = ({id}: {id: number}) => {
  const [loading, error, run, user] = useRequest<User>({} as User);
  useEffect(()=>{
    run(API.getUser(id))
  }, [run, id])

  if (loading) {
    return <Label size='tiny'><Loader inline size='tiny'active/></Label>
  }

  if (error) {
    return <Message negative content={error}/>
  }

  const {person} = user
  return <PersonLabel link person={person} labelProps={{style:{marginRight: '0.25em', marginBorrom: '0.25em'}}}/>
}

const ApproveOrCancel = ({program, onSuccess}: {program: Program, onSuccess: ()=>void}) => {
  const [loading, error, run] = useRequest(undefined);

  const approve = useCallback(()=>{
    const newStatus = program.status === ProgramStatus.RequestReserved ? ProgramStatus.Reserved : ProgramStatus.Public; 
    const body = Object.assign({}, program, {status: newStatus}); 
    run(API.updateProgram(body), onSuccess);
  }, [run, program, onSuccess]);

  if (error) {
    return <Message negative content={error}/>
  }

  return <>
    <Button disabled={loading} positive size='tiny' content='Approve' onClick={approve}/>
    <Cancel label='Deny' program={program} onSuccess={onSuccess}/>
  </>
}

const Cancel = ({label='Cancel', program, onSuccess}: {label: string, program: Program, onSuccess: ()=>void}) => {
  const [loading, error, run] = useRequest(undefined);

  const trash = useCallback(()=>{
    const body = Object.assign({}, program, {status: ProgramStatus.Deleted});
    run(API.updateProgram(body), onSuccess);
  }, [run, program, onSuccess]);

  if (error) {
    return <Message negative content={error}/>
  }

  return <Button disabled={loading} negative size='tiny' content={label} onClick={trash}/>
}


const ViewLocation = ({program}: {program: Program}) => (
  program.location !== '' ?
    <List.Item>
      <Icon name={getLocationIcon(program.location)} title='Location'/>
      {program.location === locations.ac ?
        <>at <a target='_blank' rel="noopener noreferrer" href={gmaps}>Alder Commons</a></> :
        program.location}
    </List.Item>
    : <></>
);

const ViewReservations = ({program}: {program: Program}) => (
  program.reservations && program.reservations.length ?
    <List.Item>
      <Icon name='lock' title='Room Reservations'/>
      {program.reservations.map(res => (
        <Label size='mini' key={res} style={{marginBottom: '0.25em'}}>{Reservables[res]}</Label>
      ))}
    </List.Item>
    : <></>
)

const Headers = ({program, hostPrefix}: {program: Program, hostPrefix: string}) => (
  <>
    <Header style={{marginBottom:'0rem'}} as='h1'>
      {program.title} <ProgramStatusLabel hideActive status={program.status}/>
    </Header>

    {program.host_text && <Header style={{marginTop:'0rem',color:'#555'}} as='h4'>
      {hostPrefix} <Markdown inline text={program.host_text}/>
    </Header>}
  </>
)

const getLocationIcon = (loc: string) => {
  if (loc === locations.ac) {
    return 'map marker alternate';
  }
  if (loc === locations.zoom) {
    return 'video';
  }
  return 'map pin'
}

const PugsOrMembersOnly = ({program} : {program: Program}) => {
  const memberRun = program.tags?.find(t=>t.label === 'Member-Run')
  const pugs = program.tags?.find(t=>t.label === 'PUGS')
  return <>
    {pugs && <Image centered height={100} src='/pugs-at-alder-commons.png' title={pugs.description}/>}
    {memberRun && <Label icon='exclamation' color='teal' title={memberRun.description} content={memberRun.label}/>}
  </>
}
